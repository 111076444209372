import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormGroup } from '@angular/forms';

import { StorageMap } from '@ngx-pwa/local-storage';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Users } from '../core/models/auth.model';

// MARK: Components
import { PopupAlertComponent } from '../popup/popup-alert/popup-alert.component';

import * as CryptoJS from 'crypto-js';
import { PopupMediaComponent } from '../popup/popup-media/popup-media.component';

export interface Paginator_m {
  page: number;
  limit: number;
  lastPage: number;
  totalPage: number;
}

export const Paginator = {
  page: 1,
  limit: 10,
  lastPage: 0,
  totalPage: 0,
};

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  users!: Users;

  private password = '1324576890abcdef1324576890abcdef';
  private iv = 'abcdef1324576890abcdef1324576890';

  constructor(
    private strorage: StorageMap,
    public dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  setStorage(name: string, value: any) {
    return lastValueFrom(this.strorage.set(name, value));
  }

  getStorage(name: string) {
    return lastValueFrom(this.strorage.get(name));
  }

  //-----------------MARK: ALERT---->
  async showAlert(title?: any, text?: any, type?: any, option?: any) {
    const popupData = {
      title: title,
      text: text,
      type: type,
      optBtn: option,
    };

    const alert = this.dialogService.open(PopupAlertComponent, {
      width: '320px',
      data: popupData,
      baseZIndex: 10000,
      showHeader: false, // Menghilangkan header dialog
    });

    let result;
    await firstValueFrom(alert?.onClose).then((res) => {
      return (result = res);
    });
    return result;
  }

  async showMedia(media: any[], option?: any) {
    const popupData = {
      data: media,
      optBtn: option,
    };

    const alert = this.dialogService.open(PopupMediaComponent, {
      width: '100%',
      data: popupData,
      baseZIndex: 10000,
      showHeader: false, // Menghilangkan header dialog
      styleClass: 'transparent-dialog', // Tambahkan kelas kustom
    });

    let result;
    await firstValueFrom(alert.onClose).then((res) => {
      return (result = res);
    });
    return result;
  }

  // MARKL: hashing encrypt and decrypt
  /**
   * Encrypt the given plaintext
   * @param plaintext - String to be encrypted
   * @returns Encrypted string in Base64 format
   */
  hashData(plaintext: string): string {
    const key = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.password).toString(),
    );
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.iv).toString().substr(0, 32),
    );
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv });
    return encrypted.toString();
  }

  /**
   * Decrypt the given encrypted string
   * @param encryptedData - Encrypted string in Base64 format
   * @returns Decrypted string (plaintext)
   */
  unHashData(encryptedData: string): string {
    const key = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.password).toString(),
    );
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.iv).toString().substr(0, 32),
    );
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  isSameDate(date1: any, date2: any) {
    return new Date(date1).getDate() === new Date(date2).getDate();
  }
}
