import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-popup-media',
  standalone: false,

  templateUrl: './popup-media.component.html',
  styleUrl: './popup-media.component.scss',
})
export class PopupMediaComponent {
  data: any[] = [];
  optBtn: any = {
    confirmLabel: 'Ok',
    cancelLabel: 'No',
  }

  constructor(
    private ref: DynamicDialogRef,
    private dialog: DynamicDialogConfig,
  ) {}

  ngOnInit() {
    if (this.dialog.data) {
      this.data = this.dialog.data.data ?? [];
      this.optBtn = this.dialog.data.optBtn ?? {confirmLabel: 'Ok', cancelLabel: 'No'};
    }
  }

  close() {
    this.ref.close();
  }

  redirect(data: any) {
    if (!/^https?:\/\//.test(data.url)) {
      console.error('Invalid URL');
      return;
    }
    window.open(data.externalUrl, '_blank');
  }
}
